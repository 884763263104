import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Application from './Application';
import Nav from './Nav';
import "./Home.css";
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import './Landing.scss';




function Landing() {
   
  const userStr = sessionStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : null;
  const [index, setIndex] = useState(0);

  const handleSignIn = async () => {
    const res = await axios.get('/api/auth');
    window.location.href = res.data.url;
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const captions = [
    { title: "Example Airbnb Feed", content: "This example of an Airbnb feed was generated by AI from Concept 0 in just minutes, simply by inputting a business description. It's almost as good as the real thing but requires 100x less cost, time, and expertise. With just one click, your company can have a feed like that of a multi-billion-dollar company with a huge social media team." },
    { title: "Example Airbnb Post 1", content: "Travel Memories: Travel has a way of leaving an indelible mark on our lives, creating cherished memories that stay with us forever. With Airbnb, you can make every trip unforgettable by staying in unique and memorable accommodations. Imagine waking up to panoramic views of a breathtaking landscape, or falling asleep under a canopy of stars in a cozy glamping tent. From quirky converted spaces to architecturally stunning homes, Airbnb offers a world of extraordinary stays that will make your travel memories truly extraordinary. So, why settle for ordinary when you can create moments that will be etched in your heart forever? Start planning your next adventure with Airbnb and let the magic of travel create memories that will last a lifetime. #TravelMemories #ExtraordinaryStays" },
    { title: "Example Airbnb Post 2", content: "Host Success Story: Meet David, an Airbnb host who turned his passion for hospitality into a thriving business. With a spare room in his home, David decided to list it on Airbnb and share his love for his city with travelers from around the world. Little did he know that his humble beginnings would lead to a successful hosting career. Through his warm hospitality and attention to detail, David quickly gained a reputation for providing exceptional experiences. Word of mouth spread, and soon his calendar was booked months in advance. Inspired by his success, David expanded his hosting portfolio, acquiring additional properties and becoming a Superhost. Today, David's dedication and entrepreneurial spirit have transformed his passion into a flourishing business, showcasing the limitless opportunities that Airbnb offers to hosts. So, if you're considering becoming an Airbnb host, let David's story inspire you to embark on your own hosting journey. #HostSuccessStory #AirbnbEntrepreneur" },
    { title: "Example Airbnb Post 3", content: "Travel Tips: Embark on a journey of discovery with Airbnb, where every stay is an opportunity to immerse yourself in the local culture and create unforgettable memories. Did you know that Airbnb offers unique accommodations that go beyond traditional hotels? From cozy treehouses nestled in lush forests to charming houseboats floating on serene waters, the possibilities are endless. Whether you're seeking a tranquil retreat or an urban adventure, Airbnb's diverse range of lodging options ensures there's something for every traveler's taste. So, why settle for ordinary when you can experience the extraordinary? Unlock the hidden gems of your destination and let Airbnb be your guide to extraordinary travel experiences. #AirbnbAdventures #TravelWithAirbnb" },
];

  useEffect(() => {
    const importBootstrap = async () => {
      await import('bootstrap/dist/css/bootstrap.min.css');
    };

    importBootstrap();
    // Optional: Return a cleanup function if you want to remove the styles when the component unmounts
  }, []);

  

  return (
    <div className="landing">
     
      
        <div className="center-content">
          <div className="container text-center">

            <h1 className="heading-text">Concept 0</h1>

            <div className="row landing-text-button ">
              <div className="col-md-6 ">
                {/* <h4>Social Media Made Easy</h4> */}
                <p className="text-landing">
                  <strong>It’s like having access to a team of the world's finest advertisers.
                  Simply tell us what your business is about, click, and witness AI magic crafting captivating posts. </strong>
                </p>
              </div>
              <div className="col-md-6">
                <button className="signin-button" onClick={() => handleSignIn()}>Try Now (Free)</button>
              </div>
            </div>

            <Container >
      <Row>
        <Col xs={12} md={6} className="carousel-align-right">

          {/* Carousel Implementation */}
          <Carousel className="custom-carousel " activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/img/airbnbfeed.png`}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/img/airbnbex1.png`}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/img/airbnbex2.png`}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/img/airbnbex3.png`}
                alt="Fourth slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col xs={12} md={6} className="d-flex carousel-align-left">
          <div className="carousel-container">
            <h5>{captions[index].title}</h5>
            <p className="carousel-post-description">{captions[index].content}</p>
          </div>
        </Col>
      </Row>

    </Container>

           


            <div className="row beta-landing">
              <div className="col-md-12">
                <h4 className="beta-heading"><strong>What's Next? Our Beta and Beyond</strong></h4>
                <p className="beta-text">
                Right now, we're in Beta. We're starting with what matters most:<br></br> <strong>images and text that capture your essence.</strong> </p> 
                <p className="beta-text"> But soon, we'll introduce:</p>
                <ul className="beta-text">
                  <li><strong>Typography</strong>, adding aesthetics to your feed.</li>
                  <li><strong>Automated posting</strong>, for even less effort and consistency.</li>
                  <li><strong>Video</strong>, making your story even more dynamic.</li>
                  <li><strong>All platform support</strong>, getting you online everywhere.</li>
                  <li><strong>AI models</strong>, giving you acces to the finest copywriters, creatives and advertisers.</li>
                  </ul> 
               

                <button className="signin-button button-last" onClick={() => handleSignIn()}>Try Now (Free)</button>
              </div>

              
            </div>

            


          </div>
        </div>


     

    </div>
  );
}

export default Landing;
