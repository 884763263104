import React, { useState, useEffect } from "react";
import utils from "../utils";
import Nav from "./Nav";
import "./Settings.css";
import axios from "axios"; 
const Settings = () => {
  const [isPaid, setIsPaid] = useState(false);
  const userStr = sessionStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : { email: 'ezzerino@lol.it', name: 'jimmy veg', pic: 'https://www.freshpawz.com/cdn/shop/products/Nextup5f1257851e8a535f1257851e9a7.906620455f1257851e9a7_600x.jpg' };

  useEffect(() => {
    const fetchPaidStatus = async () => {
      try {
        const response = await axios.get('/api/user-paid-status', { headers: utils.getAuthHeaders() });
        setIsPaid(response.data.paid);
      } catch (error) {
        console.error('Error fetching paid status:', error);
      }
    };

    fetchPaidStatus();
  }, [user.token]); // Only re-run the effect if user.token changes

  return (
    <div className="settings-div">
      <Nav user={user} />
      <div className="settings-container">
        <h2 className="settings-title">Settings</h2>
        <div className="user-details">
          <h4>Email: {user.email}</h4>
          <h4>Version: {isPaid ? 'Full (Paid)' : 'Trial (Free)'}</h4>
        </div>
      </div>
    </div>
  );
};

export default Settings;
