// Import necessari
import React from 'react';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Footer from "./components/Footer";
import Auth from './components/Auth';
import Settings from './components/Settings';
import Application from './components/Application';
import ImageGenerator from './components/ImageGenerator';
import Fooocus from './components/Fooocus';
import PaidApplication from './components/PaidApplication';
import Landing from './components/Landing';

const App = () => {
  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Box component="main" sx={{ flexGrow: 1, mb: 3 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/settings" element={<Settings />} />
            {/* <Route path="/app" element={<Application/>} />
            <Route path="/stable" element={<ImageGenerator/>} />
            <Route path="/fooocus" element={<Fooocus/>} />
            <Route path="/landing" element={<Landing/>} /> */}
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
};

export default App;
