import React, { useState } from "react";
import { Avatar, Box } from "@mui/material";
import { Link } from "react-router-dom";
import "./Nav.css";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const logout = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    window.location.href = '/';
  }

  return (
    <nav className="navbar-concept">
      <div className="brand">
  <Link to="/">
    <img
      alt="Logo"
      src="/img/logo.png" 
      className="logo-image"
    />
  </Link>
</div>

      {props.user ? (
        <div className={`nav-concept-links ${menuOpen ? "open" : ""}`}>
          <Link to="/settings" className="account-link">
            <div className="account-info">
              <span>My Account</span>
            </div>
          </Link>
          <Link onClick={logout}>Logout</Link>
        </div>
      ) : (<div />)}
      <div className="menu-icon" onClick={handleMenuClick}>
        <div className="hamburger-icon">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
