import React, { useState } from 'react';
import axios from 'axios';
import Application from './Application';
import Landing from './Landing';
import Nav from './Nav';
import "./Home.css";






function Home() {
  const userStr = sessionStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : null;

  const handleSignIn = async () => {
    const res = await axios.get('/api/auth');
    window.location.href = res.data.url;
  };



  return (
    <div>
      {user && <Nav user={user} />}
      {user ? (
        <Application />
      ) : (
        <Landing />
      )}
    </div>
  );
  
}

export default Home;
