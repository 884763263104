import React, { useState, useEffect, useRef } from "react";
import utils from "../utils";
import InstagramPostButton from './InstagramPostButton';
import axios from "axios";
import './Application.css';
import Nav from "./Nav";
import StripeCheckoutButton from './StripeCheckoutButton'; 

const Application = () => {
  const userStr = sessionStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : null;
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [content, setContent] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState(null); 
  const [progress, setProgress] = useState(0); 
  const [isJobComplete, setIsJobComplete] = useState(false); 
  const [isError, setIsError] = useState(false);
  const [isPaid, setIsPaid] = useState(null);
  const intervalRef = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handlePaymentSuccess = async () => {
    // Re-fetch payment status 
    try {
      const response = await axios.get('/api/user-paid-status', { headers: utils.getAuthHeaders() });
      setIsPaid(response.data.paid); // Update the payment status
    } catch (error) {
      console.error('Error re-fetching paid status:', error);
    }
  };

  const handleBusinessSelection = (e) => {
    const businessName = e.target.value;
    const business = businesses.find(b => b.name === businessName);
    
    setSelectedBusiness(business);
    setBusinessName(business ? business.name : '');
    setBusinessDescription(business ? business.business_description : '');
  };
  
  useEffect(() => {
    // Fetch user's paid status on component mount
    const fetchPaidStatus = async () => {
      try {
        const response = await axios.get('/api/user-paid-status', { headers: utils.getAuthHeaders() });
        setIsPaid(response.data.paid);
      } catch (error) {
        console.error('Error fetching paid status:', error);
        setIsPaid(false); // Assume not paid if there's an error
      }
    };

    fetchPaidStatus();
  }, []);

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setBusinessDescription(event.target.value);
  };


  const startJob = async () => {
    const jobName = selectedBusiness ? selectedBusiness.name : businessName;
  const jobDescription = selectedBusiness ? selectedBusiness.business_description : businessDescription;

  if (!jobDescription || jobDescription.trim() === '') {
    setStatusMessage("Please enter a business description.");
    return;
  }
    setIsLoading(true);
    setStatusMessage(""); // Clear previous status messages
    try {
      // Include both businessName and businessDescription in the request
      const response = await axios.post('/api/generate', {
        name: businessName, 
        description: businessDescription
      }, { headers: utils.getAuthHeaders() });
      if (response.data.success) {
        setJobId(response.data.jobId);
        setStatusMessage('Job started, please wait...');
        pollJobStatus(response.data.jobId);
      } else {
        setStatusMessage(response.data.error);
      }
    } catch (error) {
      setStatusMessage('Failed to start the job.');
      console.error(error);
    }
  };

  // Function to poll for job status
const pollJobStatus = (jobId) => {
  // If interval running -> clear before starting new one
  if (intervalRef.current) {
    clearInterval(intervalRef.current);
  }

  intervalRef.current = setInterval(async () => {
    try {
      const response = await axios.get('/api/generate', { headers: utils.getAuthHeaders() });
      if (response.data.success) {
        if (response.data.state === 'DONE') {
          clearInterval(intervalRef.current); 
          setIsJobComplete(true);
          setStatusMessage('Job completed, fetching results...');
          fetchJobResults(jobId);
          setIsLoading(false);
        } else if (response.data.state === 'IN_QUEUE' || response.data.state === 'PROCESSING') {
          setProgress(calculateProgress(response.data.position, response.data.stage));
        }
      } else {
        clearInterval(intervalRef.current); 
        setStatusMessage(response.data.error);
        setIsLoading(false);
        setIsError(true);
      }
    } catch (error) {
      clearInterval(intervalRef.current); 
      setStatusMessage('Failed to get job status.');
      setIsLoading(false);
      setIsError(true);
    }
  }, 5000); // Poll every 5 seconds
};

const fetchJobResults = async (jobId) => {
  try {
    const response = await axios.get(`/api/result/${jobId}`, { headers: utils.getAuthHeaders() });
    if (response.data.success) {
      if (response.data.job && response.data.job.payload && response.data.job.payload.images.length > 0) {
        const formattedContent = response.data.job.payload.images.map(item => {
          return {
            caption: item.caption,
            image: '/api' + item.url,  // Directly use the base64 image data
          };
        });        

        setContent(formattedContent);
        setStatusMessage('Job completed! Check results below.');
        setIsError(false); // Reset error state
      } else {
        setStatusMessage('No images were generated.');
        setIsError(true);
      }
    } else {
      setStatusMessage(response.data.error || 'Failed to fetch results.');
      setIsError(true);
    }
  } catch (error) {
    setStatusMessage('Failed to fetch results.');
    setIsError(true);
    console.error(error);
  }
};


// Function to calculate progress percentage
const calculateProgress = (position, stage) => {
  // Check if the user is paid and set the divisor accordingly
  const divisor = 4; //isPaid ? 4 : 10;
  return ((stage || position || 0) / divisor) * 100;
};


// Function to handle image click and open modal
const openModalWithImage = (image) => {
  console.log("Image clicked:", image); 
  setSelectedImage(image);
  setModalOpen(true);
};



  useEffect(() => {
    // Cleanup function to clear the interval using the ref
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    console.log("Content array:", content);
  }, [content]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get('/api/businesses', { headers: utils.getAuthHeaders() });
        console.log('Businesses fetched:', response.data.businesses);
        setBusinesses(response.data.businesses);
      } catch (error) {
        console.error('Error fetching businesses:', error.response || error.message || error);
      }
    };
  
    fetchBusinesses();
  }, []);

  const fetchImageHistory = async () => {
    try {
      let endpoint = '/api/image-history'; // Default endpoint to fetch all images for the user
      if (selectedBusiness) {
        // First, get the business ID for the selected business
        const businessIdResponse = await axios.get(`/api/business-id/${selectedBusiness.name}`, { headers: utils.getAuthHeaders() });
        const businessId = businessIdResponse.data.businessId;
        if (businessId) {
          endpoint += `/${businessId}`; // Update endpoint to fetch images for the specific business
        } else {
          console.error('No business found with the selected name');
          setStatusMessage('No business found with the selected name.');
          return;
        }
      }
      
      const response = await axios.get(endpoint, { headers: utils.getAuthHeaders() });
      setContent(response.data.images); // Assuming the API returns an array of images
    } catch (error) {
      console.error('Failed to fetch image history:', error);
      setStatusMessage('Failed to fetch image history.');
    }
  };
  
  


  return (
    
    <div className="container">
       
      <div className="input-generate-container">
        {!isJobComplete ? (
          <>
            <h2 className="title">Describe a new business or pick an existing one to begin...</h2>
            <form onSubmit={(e) => e.preventDefault()}>
  <input
    type="text"
    className="input-field"
    placeholder="Your Business Name"
    value={businessName}
    onChange={handleBusinessNameChange}
    disabled={!!selectedBusiness}
  />
  <input
    type="text"
    className="input-field"
    placeholder="A boutique Italian café with a curated wine menu and artisanal pasta creations."
    value={businessDescription}
    onChange={handleDescriptionChange}
    disabled={!!selectedBusiness}
  />
  <select
    className="input-field"
    value={selectedBusiness ? selectedBusiness.name : ''}
    onChange={handleBusinessSelection}
  >
    <option value="">Select a Business</option>
    {businesses.map((business, index) => (
      <option key={index} value={business.name}>{business.name}</option>
    ))}
  </select>
  {statusMessage && (
    <div className={`alert ${isError ? 'alert-error' : ''}`}>
      *{statusMessage}
    </div>
  )}
  <div className="button-group"> 
    {!isLoading && (
      <>
        <button 
          className="submit-button" 
          onClick={startJob}
        >
          Generate Content
        </button>
        <button className="submit-button" onClick={fetchImageHistory}>
          Image History
        </button>
      </>
    )}
  </div>
</form>

  
            {isLoading && (
              <div className="real-progress-bar">
                <div 
                  className="real-progress-bar-inner" 
                  style={{ width: `${progress}%` }}
                >
                  {progress.toFixed(0)}%
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="job-complete-message">
            <h2 className="title">
            Your custom content is ready! Scroll down to see the visuals. <br></br>
            Click any image to read its caption.
              </h2>
            </div>
            <button 
              className="submit-button" 
              onClick={() => window.location.reload()}
            >
              Try Again ↻
            </button>
          </>
        )}
      </div>
  
      <div className="grid-container">
      {!isError && content.map((item, index) => (
    <div className="grid-item" key={index} onClick={() => openModalWithImage(item)}>
      <img 
        src={item.image} 
        alt={`Generated content ${index + 1}`} 
        className="content-image"
      />
    </div>
  ))}
        {isJobComplete && isError && !isLoading && (
          <div className="alert alert-error">
            Unable to display results. Please try again later.
          </div>
        )}
      </div>
  
      {isModalOpen && (
        <div className="modal">
        <div className="modal-header">
          <div className="closeButton-container">
            <button className="closeButton" onClick={() => setModalOpen(false)}>Close X</button>
          </div>
      
          <div className="modal-image-container">
            <img src={selectedImage.image} alt="Modal content" className="modal-image" />
          </div>
          
          <div className="caption-container">
            <p className="caption">{selectedImage.caption}</p>
          </div>
        </div>
      </div>
      
       
      )}
        {isPaid === false && (
        <div className="input-generate-pay-container">
          <div className="payment-reminder">
          <h3>ⓘ You are currently using the free version. Unlock All Features for Just $9!</h3>
          <StripeCheckoutButton onPaymentSuccess={handlePaymentSuccess} user={user} />
          </div>
        </div>
      )}


    </div>

    

    
  );
  
};

export default Application;