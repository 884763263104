import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import Nav from './Nav';
import { Box } from '@mui/system';


function Auth() {

  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");
  const code = searchParams.get("code");

  useEffect(() => {
    execPost();
  }, []);

  const execPost = async () => {
    const res = await axios.post('/api/auth', { code });
    const { user, token } = res.data;
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('token', token);
    await new Promise(r => setTimeout(r, 500));
    window.location.href = '/';
  }

  return (
    <div>
      <Nav user={null} />
      {error ? (<Typography>ERROR: {error}</Typography>) : (<Box marginTop='30vh' display='flex' justifyContent='center' alignItems='center'><CircularProgress /></Box>)}
    </div>
  );
}

export default Auth;
