const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return '';
};

const getAuthHeaders = () => {
  const token = sessionStorage.getItem('token') || '';
  return { 'Authorization': `Bearer ${token}` };
}


export default {
  getAuthHeaders,
}
