import React from 'react';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import utils from "../utils";
import './Application.css';


const CheckoutForm = ({ onPaymentSuccess, user }) => {
  const stripe = useStripe();
  const elements = useElements();
  console.log('stripe key: ' + process.env.REACT_APP_STRIPE_PUBLIC_KEY)

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      // Ensure form submission is halted if Stripe has not loaded.
      alert('Payment service is currently unavailable. Please try again later.');
      return;
    }
  
    // Use the user prop directly for billing details
    const billingDetails = {
      name: user?.name,
      email: user?.email,
    };
  
    const { data: { clientSecret } } = await axios.post('/api/create-payment-intent', {}, { headers: utils.getAuthHeaders() });
  
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });
  
    if (result.error) {
      // Payment failed
      console.log(result.error.message);
      alert('Payment failed: ' + result.error.message + '. Please try again.'); // Inform the user
    } else if (result.paymentIntent.status === 'succeeded') {
      // Payment succeeded
      console.log('Payment succeeded!');
      alert('Payment successful! You can check your "paid" status in the settings.');
      try {
        const response = await axios.post('/api/update-payment-status', {}, { headers: utils.getAuthHeaders() });
        if (response.data.success) {
          console.log('Payment status updated successfully');
          onPaymentSuccess(); // Trigger any onSuccess actions
          window.location.reload(); // Refresh the page to reflect the changes
        }
      } catch (error) {
        console.error('Failed to update payment status:', error);
        alert('There was an issue updating your payment status. Please check your settings or contact support.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button className="pay-button" type="submit" disabled={!stripe}>
        Pay $9
      </button>
    </form>
  );
};

const StripeCheckoutButton = ({ onPaymentSuccess, user }) => (
  <Elements stripe={loadStripe('pk_live_51Og3JOEbXn8X36s9serCQwqV9c18UITHm6EKBZWHPvu2ix4SwVX2YDGeQWEeyQLEbnoGxqD6truX5Fz6DSY33fwV00kscfSYXO')}>
    <CheckoutForm onPaymentSuccess={onPaymentSuccess} user={user} />
  </Elements>
);

export default StripeCheckoutButton;
